import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Col } from "antd";
import { AlignLeftOutlined } from "@ant-design/icons";
import useMediaQuery from "../use.media.query/use.media.query";
import {
  NavBodyLink,
  NavBody,
  HeaderCol,
  NavVerticalButton,
  NavVertical,
  HeaderDrawer,
  HeaderHr,
  NavPurpleButton,
  NavWhiteButton,
} from "./header.style";

export const Header = () => {
  const [click, setClick] = useState(5);
  const [open, setOpen] = useState(false);
  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };

  const isMobile = useMediaQuery("(max-width: 747px)");

  return (
    <HeaderCol>
      <Col span={24}>
        {isMobile ? (
          <>
            <AlignLeftOutlined onClick={showDrawer} style={{ fontSize: "x-large" }} />
            <HeaderDrawer
              contentWrapperStyle={{ boxShadow: "none" }}
              maskStyle={{ backdropFilter: "blur(2px)" }}
              placement="left"
              onClose={onClose}
              open={open}
            >
              <NavVertical>
                <h3>INSTALLY</h3>
                <HeaderHr />
                <NavVerticalButton>
                  <NavBodyLink to="/contactus">
                    <NavWhiteButton
                      onClick={() => {
                        setClick(1);
                        setOpen(false);
                      }}
                      isActive={click === 1}
                    >
                      Contact Us
                    </NavWhiteButton>
                  </NavBodyLink>
                  <NavBodyLink to="/price">
                    <NavWhiteButton
                      onClick={() => {
                        setClick(2);
                        setOpen(false);
                      }}
                      isActive={click === 2}
                    >
                      Pricing
                    </NavWhiteButton>
                  </NavBodyLink>
                  <NavBodyLink to="/login">
                    <NavWhiteButton
                      onClick={() => {
                        setClick(3);
                        setOpen(false);
                      }}
                      isActive={click === 3}
                    >
                      Log in
                    </NavWhiteButton>
                  </NavBodyLink>
                  <NavBodyLink to="/document">
                    <NavWhiteButton
                      onClick={() => {
                        setClick(4);
                        setOpen(false);
                      }}
                      isActive={click === 4}
                    >
                      Document
                    </NavWhiteButton>
                  </NavBodyLink>
                  <NavBodyLink to="/">
                    <NavWhiteButton
                      onClick={() => {
                        setClick(0);
                        setOpen(false);
                      }}
                      isActive={click === 0}
                    >
                      Home
                    </NavWhiteButton>
                  </NavBodyLink>
                </NavVerticalButton>
              </NavVertical>
            </HeaderDrawer>
          </>
        ) : (
          <NavBody>
            <h3>INSTALLY</h3>
            <div>
              <Link to="/contactus">
                <NavWhiteButton onClick={() => setClick(1)} isActive={click === 1}>
                  Contact Us
                </NavWhiteButton>
              </Link>
              <Link to="/price">
                <NavWhiteButton onClick={() => setClick(2)} isActive={click === 2}>
                  Pricing
                </NavWhiteButton>
              </Link>
              <Link to="/login">
                <NavWhiteButton onClick={() => setClick(3)} isActive={click === 3}>
                  Log in
                </NavWhiteButton>
              </Link>
              <Link to="/document">
                <NavWhiteButton onClick={() => setClick(4)} isActive={click === 4}>
                  Document
                </NavWhiteButton>
              </Link>
              <Link to="/">
                <NavPurpleButton onClick={() => setClick(0)} isActive={click === 0}>
                  Home
                </NavPurpleButton>
              </Link>
            </div>
          </NavBody>
        )}
      </Col>
    </HeaderCol>
  );
};
