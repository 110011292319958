import React from "react";
import styled from "styled-components";
import { Col, Row } from "antd";
import { defaultColor, defaultFontSize } from "../../../theme";
import pic1 from "../../../img/pic1.png";
import { Advantage, Suitable, Content } from "./";
import bg2 from "../../../img/bg2.png";

export const Landing = () => {
  return (
    <LandingContainer>
      <IndexBody>
        <LandingBody>
          <Row justify="center" align="middle">
            <Col xs={24} md={24} lg={12}>
              <LandingLeftItems justify="space-around">
                <h1>Provide your application to the customer with us</h1>
                <p>
                  Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text
                  ever
                </p>
                <button>Get Sendspark Free</button>
              </LandingLeftItems>
            </Col>
            <Col xs={24} md={24} lg={12}>
              <LandingImage src={pic1} />
            </Col>
          </Row>
        </LandingBody>

        <PurpleCircle src={bg2} />
        <PurpleCircle></PurpleCircle>
        <Advantage />
        <LeftPurpleCircle></LeftPurpleCircle>
        <Suitable />
        <Content />
      </IndexBody>
    </LandingContainer>
  );
};

const LandingContainer = styled.div`
  background: white;
`;

const PurpleCircle = styled.img`
  position: absolute;
  right: 0px;
`;

const LeftPurpleCircle = styled.div`
  width: 800px;
  height: 700px;
  background: ${defaultColor.purple.lightestPurple};
  border-radius: 0px 1000px 600px 1000px;
  margin-top: -50px;
  margin-left: -647px;
  border: none;
  position: absolute;
`;

const IndexBody = styled.div`
  padding: 20px 80px;
  @media (min-width: 748px) and (max-width: 1023px) {
    padding: 20px 50px;
  }
  @media (max-width: 747px) {
    padding: 0px 25px;
  }
`;

const LandingBody = styled.div`
  display: flex;
  flex-direction: row;
  align-items: end;
  justify-content: space-between;
  margin: 50px 0px;
  /* @media (min-width: 748px) and (max-width: 1023px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: left;
  } */
`;

const LandingLeftItems = styled(Row)`
  /* width: 550px; */
  display: flex;
  flex-direction: column;
  -webkit-box-align: baseline;
  align-items: baseline;
  text-align: initial;
  h1 {
    font-size: ${defaultFontSize.size.biggest};
    font-weight: ${defaultFontSize.weight.eight};
  }
  p {
    color: ${defaultColor.gray.middleGray};
    font-weight: ${defaultFontSize.weight.five};
    margin: 0px 0px 30px;
    line-height: 1.5;
  }
  button {
    background: ${defaultColor.purple.darkPurple};
    color: ${defaultColor.white};
    padding: 13px 17px;
    border-radius: 5px;
    border: none;
    margin-left: 8px;
    cursor: pointer;
    font-size: ${defaultFontSize.size.inherit};
  }
  /* @media (min-width: 748px) and (max-width: 1023px) {
    margin-bottom: 35px;
    h1 {
      font-size: 47px;
    }
  } */
  @media (max-width: 747px) {
    h1 {
      font-size: 27px;
      font-weight: 800;
      line-height: 1.5;
    }
  }
`;

const LandingImage = styled.img`
  display: flex;
  width: 450px;
  margin: auto;
  @media (max-width: 747px) {
    width: 325px;
    margin-top: 20px;
  }
`;
