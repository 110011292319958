import styled from "styled-components";
import Main from "./view/main";

function App() {
  return (
    <AppBody>
      <Main />
    </AppBody>
  );
}

export default App;

const AppBody = styled.div`
  text-align: center;
`;
