import { Form, Input, Row } from "antd";
import { defaultColor } from "../../../../theme";
import {
  TermsConditions,
  CreateAccountContainer,
  RememberCheckbox,
  SigninButton,
  SigninInput,
  SignupCol,
  TitleText,
  FormBody,
} from "./sign.up.style";

export const Register = ({ setActiveTab }) => {
  const onChange2 = (e) => {
    console.log(`checked = ${e.target.checked}`);
  };

  const onFinish = (values) => {
    console.log("Success:", values);
  };
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <FormBody
      name="basic"
      labelCol={{
        span: 8,
      }}
      wrapperCol={{
        span: 16,
      }}
      style={{
        maxWidth: 600,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
      initialValues={{
        remember: true,
      }}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      autoComplete="off"
    >
      <TitleText>Sign Up</TitleText>
      <Row style={{ width: "100%" }}>
        <SignupCol span={24}>
          <Form.Item
            label="Username"
            name="username"
            style={{ margin: "auto", width: "90%", marginBottom: "25px" }}
            rules={[
              {
                required: true,
                message: "Please input your username!",
              },
            ]}
          >
            <SigninInput size="large" placeholder="Username" />
          </Form.Item>
        </SignupCol>
      </Row>
      <Row style={{ width: "100%" }}>
        <SignupCol span={24}>
          <Form.Item
            label="E-mail"
            name="E-mail"
            style={{ margin: "auto", width: "90%", marginBottom: "25px" }}
            rules={[
              {
                required: true,
                message: "Please input your E-mail!",
              },
            ]}
          >
            <SigninInput size="large" placeholder="E-mail" />
          </Form.Item>
        </SignupCol>
      </Row>
      <Row style={{ width: "100%" }}>
        <SignupCol span={24}>
          <Form.Item
            label="Password"
            name="password"
            style={{ margin: "auto", width: "90%", marginBottom: "25px" }}
            rules={[
              {
                required: true,
                message: "Please input your password!",
              },
            ]}
          >
            <Input.Password
              placeholder="Password"
              size="large"
              style={{
                marginLeft: "-45px",
                marginBottom: "3px",
                fontSize: "inherit",
                border: "none",
                boxShadow: `${defaultColor.shadow.inputPurpleShadow} 1px 10px 15px`,
              }}
            />
          </Form.Item>
        </SignupCol>
      </Row>

      <Form.Item
        name="remember"
        valuePropName="checked"
        wrapperCol={{
          offset: 0,
          span: 119,
        }}
        style={{ minWidth: "100%", margin: "10px 0px 20px" }}
      >
        <TermsConditions>
          <RememberCheckbox onChange={onChange2}>i read and agree to</RememberCheckbox>
          <a>Terms & Conditions</a>
        </TermsConditions>
      </Form.Item>

      <Form.Item
        wrapperCol={{
          offset: 0,
          span: 16,
        }}
      >
        <SigninButton type="primary" htmlType="submit">
          Create Account
        </SigninButton>
      </Form.Item>
      <Form.Item
        wrapperCol={{
          offset: 0,
          span: 116,
        }}
      >
        <CreateAccountContainer>
          <p>Already have an account?</p>
          <a onClick={() => setActiveTab("1")}>Sign in</a>
        </CreateAccountContainer>
      </Form.Item>
    </FormBody>
  );
};
