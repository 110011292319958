import React from "react";
import { Layout } from "./pages/layout";
import { RouterProvider } from "./provider/router.provider/router.provider";
import { Router } from "./provider/router";

const Main = () => {
  return (
    <RouterProvider>
      <Layout>
        <Router />
      </Layout>
    </RouterProvider>
  );
};

export default Main;
