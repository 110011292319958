import { Button, Checkbox, Form, Input, Row, Col } from "antd";
import styled from "styled-components";
import { defaultColor, defaultFontSize } from "../../../../theme";

export const TitleText = styled.h1`
  margin-bottom: 55px;
`;

export const FormBody = styled(Form)`
  min-width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .ant-col.ant-col-8.ant-form-item-label.css-dev-only-do-not-override-htwhyh {
    width: 40px !important;
    text-align: center;
  }
  .ant-form-item-with-help .ant-form-item-explain {
    text-align: start;
    margin-left: -20px;
    font-size: ${defaultFontSize.size.small};
  }
  .ant-form-item-required {
    text-align: inherit;
    width: 130px;
  }
`;

export const SignupCol = styled(Col)`
  @media (max-width: 747px) {
    .ant-form-item.css-dev-only-do-not-override-htwhyh
      .ant-row.ant-form-item-row.css-dev-only-do-not-override-htwhyh
      .ant-col.ant-col-8.ant-form-item-label.css-dev-only-do-not-override-htwhyh {
      flex: 1;
    }
    .ant-form-item.css-dev-only-do-not-override-htwhyh
      .ant-row.ant-form-item-row.css-dev-only-do-not-override-htwhyh
      .ant-col.ant-col-16.ant-form-item-control.css-dev-only-do-not-override-htwhyh {
      flex: 2;
    }
  }
`;

export const SigninInput = styled(Input)`
  box-shadow: ${defaultColor.shadow.inputPurpleShadow} 1px 10px 15px;
  margin: 0px 0px 3px -45px;
  border: none;
  font-size: ${defaultFontSize.size.inherit};
`;

export const SigninButton = styled(Button)`
  border: none;
  background: none;
  background-color: ${defaultColor.purple.darkPurple};
  padding: 9px 60px;
  border-radius: 10px;
  color: ${defaultColor.white};
  font-size: initial;
  font-weight: ${defaultFontSize.weight.six};
  margin-top: 20px;
  box-shadow: ${defaultColor.shadow.inputPurpleShadow} 1px 10px 15px;
  height: 40px;
  :hover {
    background-color: ${defaultColor.purple.middlePurple} !important ;
  }
`;

export const RememberCheckbox = styled(Checkbox)`
  font-size: ${defaultFontSize.size.smaller} !important;
  color: ${defaultColor.gray.darkGray};
  :hover .ant-checkbox-inner {
    border-color: ${defaultColor.purple.darkPurple} !important;
  }
  .ant-checkbox-checked:not(.ant-checkbox-disabled) .ant-checkbox-inner {
    background-color: ${defaultColor.purple.darkPurple} !important;
  }
`;

export const CreateAccountContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: -20px;
  p {
    color: ${defaultColor.gray.darkGray};
  }
  a {
    color: ${defaultColor.purple.darkPurple};
    padding-left: 5px;
  }
`;

export const TermsConditions = styled.div`
  display: flex;
  flex-direction: row;
  -webkit-box-align: center;
  align-items: center;
  justify-content: center;
  margin: 0px 0px 10px;
  a {
    color: ${defaultColor.purple.darkPurple};
    font-size: ${defaultFontSize.size.smaller};
  }
`;
