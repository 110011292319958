import React from "react";
import { Input, Form } from "antd";
import { ContactUsBody, ContactCol, FormBody, SigninButton, CardContainer, CardBody, SigninInput, SubjectInput } from "./contact.us.style";

const { TextArea } = Input;

export const ContactUs = () => {
  const onFinish = (values) => {
    console.log("Success:", values);
  };
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <ContactUsBody>
      <h1>Contact Us</h1>
      <p>Please use this from to contact us and we will get back to you as soon as possibel</p>
      <CardContainer>
        <ContactCol sx={24} md={24} lg={24}>
          <CardBody>
            <FormBody
              name="basic"
              labelCol={{
                span: 8,
              }}
              wrapperCol={{
                span: 16,
              }}
              style={{
                maxWidth: 600,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
              initialValues={{
                remember: true,
              }}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              autoComplete="off"
            >
              <Form.Item
                label="Name :"
                name="name"
                style={{ margin: "auto", width: "90%", marginBottom: "25px" }}
                rules={[
                  {
                    required: true,
                    message: "Please input your name!",
                  },
                ]}
              >
                <SigninInput size="large" />
              </Form.Item>
              <Form.Item
                label="E-mail :"
                name="E-mail"
                style={{ margin: "auto", width: "90%", marginBottom: "25px" }}
                rules={[
                  {
                    required: true,
                    message: "Please input your email!",
                  },
                ]}
              >
                <SigninInput size="large" />
              </Form.Item>
              <Form.Item
                label="Subject :"
                name="Subject"
                style={{ margin: "auto", width: "90%", marginBottom: "25px" }}
                rules={[
                  {
                    required: true,
                    message: "Please input your subject!",
                  },
                ]}
              >
                <SigninInput size="large" />
              </Form.Item>
              <Form.Item
                label="Message :"
                name="message"
                style={{ margin: "auto", width: "90%", marginBottom: "25px" }}
                rules={[
                  {
                    required: true,
                    message: "Please input your message!",
                  },
                ]}
              >
                <SubjectInput rows={4} />
              </Form.Item>

              <Form.Item
                wrapperCol={{
                  offset: 0,
                  span: 16,
                }}
              >
                <SigninButton type="primary" htmlType="submit">
                  Send Message
                </SigninButton>
              </Form.Item>
            </FormBody>
          </CardBody>
        </ContactCol>
      </CardContainer>
    </ContactUsBody>
  );
};
