import { Card, Col, Input, Form, Button, Row } from "antd";
import { defaultColor, defaultFontSize } from "../../../theme";
import styled from "styled-components";

const { TextArea } = Input;

export const ContactUsBody = styled.div`
  p {
    padding: 0px 30px;
    line-height: 2;
  }
`;

export const ContactCol = styled(Col)`
  width: 50%;
  @media (min-width: 748px) and (max-width: 1023px) {
    width: 60%;
  }
  @media (max-width: 747px) {
    width: 85%;
  }
`;

export const FormBody = styled(Form)`
  min-width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .ant-col.ant-col-8.ant-form-item-label.css-dev-only-do-not-override-htwhyh {
    width: 40px !important;
    text-align: center;
  }
  .ant-col .ant-col-16 .ant-form-item-control .css-dev-only-do-not-override-htwhyh {
    flex: auto !important;
  }
  .ant-form-item-with-help .ant-form-item-explain {
    text-align: start;
    margin-left: -40px;
    font-size: ${defaultFontSize.size.small};
  }
  label.ant-form-item-required {
    width: 100px;
    text-align: left;
  }
  @media (min-width: 748px) and (max-width: 1023px) {
    label.ant-form-item-required {
      margin-right: 8px;
    }
  }
  @media (max-width: 747px) {
    .ant-col.ant-col-8.ant-form-item-label.css-dev-only-do-not-override-htwhyh {
      text-align: left;
    }
  }
`;

export const SigninButton = styled(Button)`
  border: none;
  background: none;
  background-color: ${defaultColor.purple.darkPurple};
  padding: 6px 40px;
  border-radius: 10px;
  color: ${defaultColor.white};
  font-size: initial;
  font-weight: ${defaultFontSize.weight.six};
  margin-top: 20px;
  box-shadow: ${defaultColor.shadow.inputPurpleShadow} 1px 10px 15px;
  height: 40px;
  :hover {
    background-color: ${defaultColor.purple.middlePurple} !important ;
  }
`;

export const CardContainer = styled(Row)`
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 80px;
`;

export const CardBody = styled(Card)`
  /* width: 130%; */
  border: none;
  margin-top: 15px;
  .ant-card-body {
    background: ${defaultColor.purple.lightPurple};
    border-radius: 10px;
    padding: 30px 0px 10px;
  }
`;

export const SigninInput = styled(Input)`
  /* width: 100%; */
  box-shadow: ${defaultColor.shadow.inputPurpleShadow} 1px 10px 15px;
  margin: 0px 0px 0px -85px;
  border: none;
  font-size: ${defaultFontSize.size.inherit};
  @media (max-width: 747px) {
    margin: auto;
  }
`;

export const SubjectInput = styled(TextArea)`
  /* width: 100%; */
  box-shadow: ${defaultColor.shadow.inputPurpleShadow} 1px 10px 15px;
  margin: 0px 0px 0px -85px;
  border: none;
  font-size: ${defaultFontSize.size.inherit};
  @media (max-width: 747px) {
    margin: auto;
  }
`;
