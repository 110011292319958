import React, { useState } from "react";
import { Tabs, Row, Col } from "antd";
import styled from "styled-components";
import { defaultColor, defaultFontSize } from "../../../theme";
import { Signin, Register } from "./";

export const Login = () => {
  const [activeTab, setActiveTab] = useState("1");

  const handleTabChange = (key) => {
    setActiveTab(key);
  };

  const text = [
    { name: "Sign In", children: <Signin setActiveTab={setActiveTab} /> },
    { name: "Sign Up", children: <Register setActiveTab={setActiveTab} /> },
  ];

  return (
    <LoginRow justify="center">
      <LoginCol sx={24} md={16} lg={12}>
        <TabStyle
          activeKey={activeTab}
          onChange={handleTabChange}
          centered
          items={text.map((text, i) => {
            const id = String(i + 1);
            return {
              label: <span>{text.name}</span>,
              key: id,
              children: <div>{text.children}</div>,
            };
          })}
        />
      </LoginCol>
    </LoginRow>
  );
};

const TabStyle = styled(Tabs)`
  /* width: 35%; */
  margin: auto;
  border-radius: 20px;
  border: 1px solid ${defaultColor.gray.lightGray};
  margin-top: 50px;
  margin-bottom: 80px;
  .ant-tabs-nav {
    background: ${defaultColor.purple.darkPurple};
    border-radius: 19px 19px 0px 0px;
    color: ${defaultColor.white};
    font-weight: ${defaultFontSize.weight.seven};
  }
  .ant-tabs-tab-active .ant-tabs-tab-btn {
    color: ${defaultColor.white} !important;
  }
  .ant-tabs-tab-btn {
    color: ${defaultColor.purple.tabLightPurple} !important;
  }
  .ant-tabs-tab.ant-tabs-tab-active {
    font-size: ${defaultFontSize.size.large};
  }
  .ant-tabs-ink-bar.ant-tabs-ink-bar-animated {
    left: 0px;
    width: 55px;
    background-color: ${defaultColor.purple.tabLightPurple};
    padding: 2px 0px 1px;
  }
`;

const LoginCol = styled(Col)`
  @media (max-width: 747px) {
    width: 90%;
  }
`;

const LoginRow = styled(Row)`
  @media (max-width: 747px) {
  }
`;
