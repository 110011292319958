export const defaultFontSize = {
  size: {
    smaller: "smaller",
    small: "small",
    initial: "initial",
    inherit: "inherit",
    large: "large",
    larger: "larger",
    big: "x-large",
    biggest: "xxx-large",
  },
  weight: {
    one: "100",
    two: "200",
    three: "300",
    four: "400",
    five: "500",
    six: "600",
    seven: "700",
    eight: "800",
    nine: "900",
  },
};
