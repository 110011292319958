import React from "react";
import { Routes, Route } from "react-router";
import { Landing } from "../../pages/landing/landing";
import { Login } from "../../pages/login";
import { PricingPage } from "../../pages/pricing.page";
import { ContactUs } from "../../pages/cantact.us";
import { Document } from "../../pages/document";

export const Router = () => {
  return (
    <Routes>
      <Route exact path="/" element={<Landing />} />
      <Route exact path="/login" element={<Login />} />
      <Route exact path="/price" element={<PricingPage />} />
      <Route exact path="/contactus" element={<ContactUs />} />
      <Route exact path="/document" element={<Document />} />
    </Routes>
  );
};
