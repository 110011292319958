import styled from "styled-components";
import { ArrowRightOutlined, RightOutlined } from "@ant-design/icons";
import { defaultColor, defaultFontSize } from "../../../theme";
import { Row, Col } from "antd";

export const FooterContainer = styled.div`
  margin-top: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const FooterLoginInput = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  input {
    border: none;
    background: ${defaultColor.purple.inputLightPurple};
    color: ${defaultColor.white} !important;
    padding: 10px;
    width: 200px;
    border-radius: 7px 0px 0px 7px;
    ::placeholder {
      color: ${defaultColor.purple.darkPurple};
      font-size: ${defaultFontSize.size.larger};
    }
  }
  @media (min-width: 748px) and (max-width: 1023px) {
    width: 170px;

    input {
      ::placeholder {
        font-size: 12px;
      }
    }
  }
`;

export const InputRightIcon = styled(RightOutlined)`
  padding: 8px;
  background: ${defaultColor.white};
  border-radius: 0px 7px 7px 0px;
  color: ${defaultColor.purple.darkPurple};
  cursor: pointer;
  font-size: ${defaultFontSize.size.larger};
`;

export const FooterBody = styled(Row)`
  display: flex;
  flex-direction: row;
  align-items: baseline;
  justify-content: space-between;
  background: ${defaultColor.purple.darkPurple};
  border-radius: 50px 0px 0px 0px;
  padding: 61px 0px 30px;
  @media (min-width: 748px) and (max-width: 1023px) {
    width: 100%;
    justify-content: space-evenly;
    padding: 50px 6px 20px;
  }
  @media (max-width: 747px) {
    (.css-dev-only-do-not-override-htwhyh).ant-row {
      display: unset;
    }
    /* display: flex; */
    flex-direction: column;
    align-items: baseline;
  }
`;

export const InstallyTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: baseline;
  flex: 1;
  color: ${defaultColor.white};
  padding: 0px 50px;
  p {
    text-align: initial;
    line-height: 1.5;
    font-size: ${defaultFontSize.size.small};
  }
  @media (min-width: 748px) and (max-width: 1023px) {
    /* flex: none; */
    padding: 0px 18px;
  }
  p {
    font-size: 12px;
    /* width: 200px; */
  }
  @media (max-width: 747px) {
    margin-bottom: 15px;
  }
`;

export const FooterLinkContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: baseline;
  flex: 1;
  color: ${defaultColor.white};
  padding: 0px 50px;
  a {
    margin: 8px 0px;
    cursor: pointer;
    font-size: ${defaultFontSize.size.small};
  }
  @media (min-width: 748px) and (max-width: 1023px) {
    margin-left: 40px;
    padding: 0px 18px;
  }
  h4 {
    width: 86px;
  }
  a {
    font-size: 12px;
    color: ${defaultColor.white};
    font-size: ${defaultFontSize.size.small};
  }
  @media (max-width: 747px) {
    margin-bottom: 15px;

    a {
      font-size: 12px;
      color: ${defaultColor.white};
      font-size: ${defaultFontSize.size.small};
      margin-bottom: 4px;
    }
  }
`;

export const FooterLloginContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: baseline;
  flex: 1;
  color: ${defaultColor.white};
  padding: 0px 50px;
  p {
    text-align: initial;
    line-height: 1.5;
    margin-top: 0px;
    font-size: ${defaultFontSize.size.small};
  }
  @media (min-width: 748px) and (max-width: 1023px) {
    /* flex: none; */
    padding: 0px 18px;
  }
  p {
    font-size: 12px;
  }
  @media (max-width: 747px) {
    margin-bottom: 15px;
  }
`;

export const FooterSocialContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  color: ${defaultColor.white};
  padding: 0px 50px;
  @media (min-width: 748px) and (max-width: 1023px) {
    /* flex: none; */
    padding: 0px 18px;
  }
  p {
    font-size: 12px;
  }
`;

export const SocialIconContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  @media (min-width: 748px) and (max-width: 1023px) {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  @media (max-width: 747px) {
    display: flex;
    flex-direction: row;
    align-items: baseline;
  }
`;

export const LearninBox = styled(Col)`
  width: 50%;
  display: flex;
  margin-bottom: 13px;
  flex-direction: row;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  justify-content: space-between;
  margin: -46px auto;
  border-radius: 5px 5px 35px;
  padding: 24px 30px;
  background: ${defaultColor.white};
  box-shadow: 1px 2px 18px ${defaultColor.shadow.gray};
  p {
    color: ${defaultColor.purple.darkPurple};
    font-weight: ${defaultFontSize.weight.seven};
    font-size: ${defaultFontSize.size.big};
  }
  @media (min-width: 748px) and (max-width: 1023px) {
    margin-top: -46px;
    margin-left: auto;
    margin-right: auto;
    width: 50%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
  }
  p {
    font-weight: ${defaultFontSize.weight.seven};
    font-size: ${defaultFontSize.size.big};
    margin-right: 20px;
  }
  @media (max-width: 747px) {
    height: 90px;
    width: 100%;
  }
  p {
    font-size: 20px;
  }
`;

export const ArrowButton = styled(ArrowRightOutlined)`
  color: ${defaultColor.white};
  background: ${defaultColor.purple.darkPurple};
  border-radius: 50px;
  padding: 10px;
  font-size: ${defaultFontSize.size.big};
  box-shadow: 1px 2px 16px ${defaultColor.shadow.purple};
  cursor: pointer;
  @media (max-width: 747px) {
    font-size: large;
    margin-left: 10px;
  }
`;
