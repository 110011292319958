export const defaultColor = {
  gray: {
    lightGray: "#e9ecef",
    middleGray: "rgb(149 156 164)",
    darkGray: "#6c757d",
  },
  purple: {
    tabLightPurple: "#d2a5ff",
    inputLightPurple: "rgb(217, 183, 255)",
    lightestPurple: "rgba(239, 238, 255, 0.51)",
    lightPurple: "#e7d8f7",
    middlePurple: "rgb(154 90 219)",
    darkPurple: "#7014cc",
  },
  white: "#ffffff",
  black: "#000000",
  shadow: {
    gray: "rgb(233 236 239) ",
    purple: "rgb(154 90 219) ",
    inputPurpleShadow: "rgb(231 206 255 / 38%)",
  },
};
