import styled from "styled-components";
import { defaultColor, defaultFontSize } from "../../../theme";
import { Link } from "react-router-dom";
import { Drawer } from "antd";
import { Col, Row } from "antd";

export const HeaderHr = styled.hr`
  height: 1px;
  background-color: #dcb9ff;
  width: 82%;
  border: none;
  margin-bottom: 20px;
`;

export const HeaderDrawer = styled(Drawer)`
  width: 80% !important;

  .ant-drawer-body {
    padding: 0px;
    box-shadow: none;
  }
  .ant-drawer-header.ant-drawer-header-close-only {
    display: none;
  }
`;

export const NavVertical = styled.div`
  display: flex;
  flex-direction: column;
  align-items: baseline;
  @media (min-width: 748px) and (max-width: 1023px) {
    width: 100%;
  }
  h3 {
    font-weight: 900;
    font-size: x-large;
    color: purple;
    margin: 32px 0px 26px 18px;
    color: ${defaultColor.purple.darkPurple};
  }
`;

export const NavVerticalButton = styled.div`
  display: flex;
  flex-direction: column;
  align-items: baseline;
  @media (max-width: 747px) {
    width: 100%;
  }
`;

export const HeaderCol = styled(Row)`
  @media (max-width: 747px) {
    .ant-col.ant-col-24.css-dev-only-do-not-override-htwhyh {
      background: #7114cc;
      height: 45px;
      color: white;
      text-align: left;
      padding: 10px;
    }
  }
`;

export const NavBody = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 20px 80px 0px;
  @media (min-width: 748px) and (max-width: 1023px) {
    padding: 20px 50px 0px;
  }
  h3 {
    font-weight: ${defaultFontSize.weight.nine};
    font-size: ${defaultFontSize.size.big};
    color: ${defaultColor.purple.darkPurple};
  }
  @media (max-width: 747px) {
    padding: 20px 50px 0px;
    Link {
      width: 85%;
    }
  }
`;

export const NavBodyLink = styled(Link)`
  @media (max-width: 747px) {
    width: 83%;
  }
`;

export const NavWhiteButton = styled.button`
  border: none;
  background: none;
  border-radius: 5px;
  background: ${({ isActive }) => (isActive ? `${defaultColor.gray.lightGray} ` : "")};
  margin: 0px 7px;
  padding: 10px 17px;
  font-size: ${defaultFontSize.size.inherit};
  :hover {
    background: ${defaultColor.gray.lightGray};
    border-radius: 5px;
    cursor: pointer;
  }
  @media (max-width: 747px) {
    font-size: large;
    width: 100%;
    text-align: start;
    margin-bottom: 5px;
    background: ${({ isActive }) => (isActive ? `${defaultColor.gray.lightGray} ` : "")};
    padding: 13px;
    :hover {
      width: 100%;
      padding: 13px;
      border-radius: 8px;
      /* width: 180px; */
      text-align: start;
      margin-bottom: 5px;
      background: ${defaultColor.gray.lightGray};
    }
  }
`;

export const NavPurpleButton = styled.button`
  background: ${({ isActive }) => (isActive ? `${defaultColor.purple.darkPurple} ` : `  ${defaultColor.purple.middlePurple}`)};
  color: ${defaultColor.white};
  box-shadow: ${({ isActive }) => (isActive ? `0px 7px 9px ${defaultColor.shadow.inputPurpleShadow} ` : "")};
  padding: 8px 14px;
  border-radius: 5px;
  border: none;
  margin-left: 8px;
  cursor: pointer;
  font-size: ${defaultFontSize.size.inherit};
  :hover {
    background: ${defaultColor.purple.darkPurple};
    box-shadow: 0px 7px 9px ${defaultColor.shadow.inputPurpleShadow};
  }
`;
