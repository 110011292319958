import { Form, Input, Row } from "antd";
import { defaultColor } from "../../../../theme";
import {
  CreateAccountContainer,
  RememberCheckbox,
  SigninButton,
  QuestionBottemInputs,
  SigninInput,
  SigninCol,
  FormBody,
  TitleText,
} from "./sign.in.style";

export const Signin = ({ setActiveTab }) => {
  const onFinish = (values) => {
    console.log("Success:", values);
  };
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const onChange = (e) => {
    console.log(`checked = ${e.target.checked}`);
  };

  return (
    <FormBody
      name="basic"
      labelCol={{
        span: 8,
      }}
      wrapperCol={{
        span: 16,
      }}
      style={{
        maxWidth: 600,
        display: "flex",
        flexDirection: "column",
      }}
      initialValues={{
        remember: true,
      }}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      autoComplete="off"
    >
      <TitleText>Sign In</TitleText>
      <Row style={{ width: "100%" }}>
        <SigninCol span={24}>
          <Form.Item
            label="Username"
            name="username"
            style={{ margin: "auto", width: "90%", marginBottom: "30px" }}
            rules={[
              {
                required: true,
                message: "Please input your username!",
              },
            ]}
          >
            <SigninInput size="large" placeholder="Username" />
          </Form.Item>
        </SigninCol>
      </Row>

      <Row style={{ width: "100%" }}>
        <SigninCol span={24}>
          <Form.Item
            label="Password"
            name="password"
            style={{ margin: "auto", width: "90%", marginBottom: "5px" }}
            rules={[
              {
                required: true,
                message: "Please input your password!",
              },
            ]}
          >
            <Input.Password
              placeholder="Password"
              size="large"
              style={{
                marginLeft: "-45px",
                fontSize: "inherit",
                border: "none",
                boxShadow: `${defaultColor.shadow.inputPurpleShadow} 1px 10px 15px`,
                marginBottom: "3px",
              }}
            />
          </Form.Item>
        </SigninCol>
      </Row>

      <Form.Item
        name="remember"
        valuePropName="checked"
        wrapperCol={{
          offset: 0,
          span: 119,
        }}
        style={{ minWidth: "100%" }}
      >
        <QuestionBottemInputs>
          <RememberCheckbox onChange={onChange}>Remember me</RememberCheckbox>
          <a>Forgot password?</a>
        </QuestionBottemInputs>
      </Form.Item>

      <Form.Item
        wrapperCol={{
          offset: 0,
          span: 16,
        }}
      >
        <SigninButton type="primary" htmlType="submit">
          Sign In
        </SigninButton>
      </Form.Item>
      <Form.Item
        wrapperCol={{
          offset: 0,
          span: 116,
        }}
      >
        <CreateAccountContainer>
          <p>Don't have an account?</p>
          <a onClick={() => setActiveTab("2")}>Create</a>
        </CreateAccountContainer>
      </Form.Item>
    </FormBody>
  );
};
