import styled from "styled-components";
import { defaultFontSize, defaultColor } from "../../../../theme";

export const PurpleCircle = styled.img`
  position: absolute;
  right: 0px;
  margin-top: 200px;
`;

export const LeftPurpleCircle = styled.div`
  width: 800px;
  height: 700px;
  background: ${defaultColor.purple.lightestPurple};
  border-radius: 0px 1000px 600px 1000px;
  margin-top: -50px;
  border: none;
  position: absolute;
  left: -514px;
  rotate: 347deg;
`;

export const SuitableBody = styled.div`
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 80px;
  h1 {
    font-weight: 800;
  }
`;

export const SuitableItems = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  margin: 30px 0px;
  position: relative;
  img {
    width: 90% !important;
  }
  @media (min-width: 748px) and (max-width: 1023px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: auto;
    margin-top: 80px;
  }
  img {
    width: 90%;
  }
  @media (max-width: 747px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: auto;
    margin-top: 40px;
  }
  img {
    width: 90%;
  }
`;

export const SuitableItemsRevers = styled.div`
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  margin: 30px 0px;
  position: relative;
  img {
    width: 90% !important;
  }
  @media (min-width: 748px) and (max-width: 1023px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: auto;
    margin-top: 80px;
  }
  img {
    width: 90%;
  }
  @media (max-width: 747px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: auto;
    margin-top: 40px;
  }
  img {
    width: 90%;
  }
`;

export const SuitableItemsDetail = styled.div`
  display: flex;
  flex-direction: column;
  align-items: baseline;
  text-align: initial;
  h2 {
    font-weight: ${defaultFontSize.weight.eight};
  }
  p {
    line-height: 1.5;
    color: ${defaultColor.gray.middleGray};
    font-weight: ${defaultFontSize.weight.five};
    margin-bottom: 22px;
  }
  button {
    background: ${defaultColor.purple.darkPurple};
    color: ${defaultColor.white};
    padding: 8px 14px;
    border-radius: 5px;
    border: none;
    cursor: pointer;
    font-size: ${defaultFontSize.size.inherit};
  }
  @media (max-width: 747px) {
    h2 {
      font-weight: 700;
      font-size: 18px;
      margin-bottom: 2px;
    }
  }
`;
