import React from "react";
import styled from "styled-components";
import { defaultColor, defaultFontSize } from "../../../theme";

export const PricingPage = () => {
  return (
    <PricingBody>
      <h1>Pricing Page</h1>
      <CardsContainer>
        <CardBody>
          <h1>Start</h1>
          <h2>Free</h2>
          <p>1 User</p>
          <p>1 Project</p>
          <button>Order Now</button>
        </CardBody>
        <CardBody>
          <h1>Basic</h1>
          <h2>$19.99</h2>
          <p>5 User</p>
          <p>5 Project</p>
          <button>Order Now</button>
        </CardBody>
        <CardBody>
          <h1>Medium</h1>
          <h2>$49.99</h2>
          <p>100 User</p>
          <p>100 Project</p>
          <button>Order Now</button>
        </CardBody>
        <CardBody>
          <h1>Expert</h1>
          <h2>$199.99</h2>
          <p>1 User</p>
          <p>1 Project</p>
          <button>Order Now</button>
        </CardBody>
      </CardsContainer>
    </PricingBody>
  );
};

const PricingBody = styled.div`
  @media (max-width: 747px) {
    text-align: center;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
  }
`;

const CardBody = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 290px;
  padding: 50px 0px 40px;
  border-radius: 20px;
  background: ${defaultColor.gray.lightGray};
  transition: 0.5s;
  margin: 0px 10px 40px;
  h2 {
    margin-bottom: 50px;
  }
  p {
    margin: 0px 0px 10px;
  }
  button {
    border: none;
    background: none;
    background-color: ${defaultColor.purple.darkPurple};
    border-radius: 10px;
    padding: 10px 25px;
    color: ${defaultColor.white};
    font-weight: ${defaultFontSize.weight.six};
    margin-top: 25px;
    cursor: pointer;
    font-size: ${defaultFontSize.size.inherit};
  }
  :hover {
    box-shadow: 1px 11px 25px ${defaultColor.shadow.inputPurpleShadow};
    background: ${defaultColor.shadow.inputPurpleShadow};
    width: 300px;
    padding: 60px 0px 50px;
  }
  @media (min-width: 748px) and (max-width: 1023px) {
    margin-bottom: 40px;
  }
  @media (max-width: 747px) {
    margin-bottom: 40px;
  }
`;

const CardsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-evenly;
  margin: 70px 0px 80px;
  @media (min-width: 748px) and (max-width: 1023px) {
    display: flex;
    flex-wrap: wrap;
  }
  @media (max-width: 747px) {
    display: flex;
    flex-wrap: wrap;
  }
`;
