import { FacebookOutlined, LinkedinOutlined, TwitterOutlined, GithubOutlined } from "@ant-design/icons";
import {
  ArrowButton,
  LearninBox,
  SocialIconContainer,
  FooterSocialContainer,
  FooterLloginContainer,
  FooterLinkContainer,
  InstallyTextContainer,
  FooterBody,
  InputRightIcon,
  FooterLoginInput,
  FooterContainer,
} from "./footer.style";
import { Row, Col } from "antd";

export const Footer = () => {
  return (
    <FooterContainer>
      <Row>
        <LearninBox sx={12} md={24} lg={24}>
          <p>Learning how to work with this site</p>
          <ArrowButton />
        </LearninBox>
      </Row>
      <FooterBody>
        <Col sx={24} md={6} lg={6}>
          <InstallyTextContainer>
            <h1>instally</h1>
            <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. the printing and typesetting industry.</p>
          </InstallyTextContainer>
        </Col>
        <Col sx={24} md={6} lg={6}>
          <FooterLinkContainer>
            <h4>Quick links</h4>
            <a>Slouyion</a>
            <a>Blog</a>
            <a>Pricing</a>
          </FooterLinkContainer>
        </Col>
        <Col sx={24} md={6} lg={6}>
          <FooterLloginContainer>
            <h4>Log in</h4>
            <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
            <FooterLoginInput>
              <input color="white" placeholder="email" />
              <InputRightIcon />
            </FooterLoginInput>
          </FooterLloginContainer>
        </Col>
        <Col sx={24} md={6} lg={6}>
          <FooterSocialContainer>
            <h4>Social</h4>
            <SocialIconContainer>
              <FacebookOutlined style={{ fontSize: "xx-large", margin: "3px 3px" }} />
              <LinkedinOutlined style={{ fontSize: "xx-large", margin: "3px 3px" }} />
              <TwitterOutlined style={{ fontSize: "xx-large", margin: "3px 3px" }} />
              <GithubOutlined style={{ fontSize: "xx-large", margin: "3px 3px" }} />
            </SocialIconContainer>
          </FooterSocialContainer>
        </Col>
      </FooterBody>
    </FooterContainer>
  );
};
